/* Styles responsive pour les appareils mobiles et tablettes */

@media (max-width: 768px) {
    .contact-form-container {
        max-width: 80%; /* Augmente la largeur maximale pour les petits écrans */
        margin: 3vh auto;
    }

    /* Afficher les champs les uns en dessous des autres */
    .form-row {
        display: block; /* Plus de flexbox, les éléments sont en bloc */
    }

    .half-width, .full-width {
        width: 100%; /* Chaque champ prend toute la largeur */
        margin-right: 0; /* Supprimer l'espacement entre les champs */
    }

    #details {
        min-height: 30vh; /* Réduit la hauteur minimale de la zone de texte */
    }

    .submit-btn {
        font-size: 16px; /* Réduire légèrement la taille du bouton pour mobile */
        padding: 10px;
    }
}
