@media (max-width: 768px) {

    .cards-grid{
        margin:0 5%;
        grid-template-columns: repeat(2, 1fr);
    }

    .prestationsTitle{
        width:90%;
        margin-left:5%;
        font-size:3vh;
    }

    .modal-content {
        width:70%
    }
}