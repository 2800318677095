.contactBackground{
    background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.8)),url("../../medias/pictures/illustrationPictures/CouvertureLivre.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 40%;
}

.contactPageContainer{
    width:64%;
    margin-left:18%;
    background-color: rgba(0,0,0,0.7);
    margin-bottom:0;
    padding-bottom:20px;
}

.contactTextContainer {
    display:flex;
    justify-content:center;
    flex-direction:column;
    padding-top:10px;
    margin-bottom:0;
    padding-bottom:0;
    color:white;
}

.animated-text {
    font-size: 4vh;
    text-align: center;
    font-family: Tahoma, sans-serif;
    min-height: 1.5em;
    display: inline-block;
    vertical-align: middle;
}

/* Animation d'apparition avec oscillation "cartoon" */
.bounce-in {
    display: inline-block;
    animation: bounceIn 0.5s ease forwards;
}

/* Animation de disparition en réduisant la taille */
.bounce-out {
    display: inline-block;
    animation: bounceOut 0.3s ease forwards;
}

@keyframes bounceIn {
    0% {
        transform: scale(0.3) translateY(-50px);
        opacity: 0;
    }
    50% {
        transform: scale(1.1) translateY(0);
        opacity: 1;
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

@keyframes bounceOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.3) translateY(50px);
        opacity: 0;
    }
}

.contactText{
    text-align:center;
    font-family:Tahoma, sans-serif;
    margin:0;
    padding:0;
}
