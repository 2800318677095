.prestations-container {
    text-align: center;
    background:linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.8)), url("../../medias/pictures/illustrationPictures/Nonne.jpg");
    background-size:cover;
    background-position:0 20%;
    padding:0 0 0 0;
}

.prestationsTitle{
    font-family:"Another Shabby", sans-serif;
    font-size:4vh;
    color:white;
    background-color:rgba(0,0,0,0.6);
    width:60%;
    margin-left:20%;
    margin-bottom:0;
    margin-top:0;
    padding-top:40px;
}

.cards-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 0 20% 0 20%;
    padding:20px 20px 40px 20px;
    background-color:rgba(0,0,0,0.6);
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    cursor:pointer;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}


.card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.card-title {
    font-size: 1.5rem;
    margin: 15px 0;
}

.card-description {
    font-size: 1rem;
    padding: 0 15px 15px;
    color: #555;
}


/* MODALE */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);  /* Fond noir avec 20% de transparence */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;  /* Assurer que la modale soit au-dessus du reste */
}

/* Contenu de la modale */
.modal-content {
    background-color: white;
   width:35%;

    padding: 20px;
    border-radius: 8px;
    position: relative;  /* Pour positionner le bouton de fermeture */
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    overflow: auto;
}

/* Bouton de fermeture (croix) */
.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;  /* Fond rouge */
    color: white;  /* Croix blanche */
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;  /* Bouton rond */
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Conteneur pour l'image principale et les images secondaires */
.modal-images-container {
    display: flex;
    justify-content: center;  /* Gérer l'espacement */
    gap: 5%;  /* Ajout de l'espace entre les images */
    margin-bottom: 20px;
}

/* Image principale ou secondaire, par défaut occupe 100% */
.modal-image {
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Si aucune image secondaire (image principale seule) */
.modal-images-container:not(.has-secondary-1):not(.has-secondary-2) .modal-image {
    width: 100%;
    max-height:100%;  /* Image principale occupe 80% de la hauteur */
}

/* Si une seule image secondaire */
.modal-images-container.has-secondary-1 .modal-image {
    width: 45%;  /* Chaque image prend 45% de la largeur */
    max-height: 50%;  /* Images prennent 50% de la hauteur */
}

/* Si deux images secondaires */
.modal-images-container.has-secondary-2 .modal-image {
    width: 30%;  /* Chaque image prend 30% de la largeur */
    max-height: 40%;  /* Images prennent 40% de la hauteur */
}

/* Titre de la modale */
.modal-title {
    font-size: 2rem;
    margin:0;
}

/* Description longue dans la modale */
.modal-description {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.5;
    max-height: 20%;  /* Limiter la hauteur de la description */
}
