/* Bandeau des partenaires pour mobile */
.partners-carousel-responsive {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.carousel-wrapper-responsive {
    position: relative;
    width: 100%;
    height: 150px;
    display: flex;
    overflow: hidden;
}

.carousel-hidden-left-responsive,
.carousel-hidden-right-responsive {
    position: absolute;
    top: 0;
    width: 33%; /* Cacher 33% de chaque côté */
    height: 100%;
    background-color: black;
    z-index: 2; /* Placer au-dessus des éléments visibles */
}

.carousel-hidden-left-responsive {
    left: 0;
}

.carousel-hidden-right-responsive {
    right: 0;
}

.carousel-visible-wrapper-responsive {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.carousel-item-responsive {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease-in-out;
    background-color: black;
}

.partner-logo-responsive {
    max-width: 70%;
    max-height: 80%;
    object-fit: contain;
}

.animate-left-responsive {
    animation: slide-left-responsive 0.5s forwards;
}

.animate-right-responsive {
    animation: slide-right-responsive 0.5s forwards;
}

@keyframes slide-left-responsive {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slide-right-responsive {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.carousel-btn-responsive {
    position: absolute;
    background-color: #fff;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 18px;
    z-index: 10;
    border-radius: 50%; /* Rendre les boutons ronds */
    width: 40px;
    height: 40px; /* S'assurer que les boutons soient bien ronds */
}

.prev-btn-responsive {
    left: 10%;
}

.next-btn-responsive {
    right: 10%;
}

.carousel-btn-responsive:hover {
    background-color: #ddd;
}
