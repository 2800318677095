/* CSS DE LA PAGE PRINCIPALE */

@font-face {
    font-family: 'Tomatoes';
    src: url('../../medias/fonts/Tomatoes.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.topBanner {
    position: relative;
    height: 35vh;
}

.topBannerPicture {
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6), rgba(0,0,0,1)), url("../../medias/pictures/illustrationPictures/TeamFoire.jpg");
    background-size: cover;
    background-position: 0 25%;
    background-repeat: no-repeat;
}

.topBannerQuote {
    position: absolute;
    top: 12vh;
    margin: 0;
    left: 32%;
    width: 36%;
    color: white;
    font-size: 30px;
    font-family: "Tomatoes", sans-serif;
    text-align:center;
}




.servicesBubbles {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-color: black;
}

.servicesBubbles h1 {
    margin-bottom: 40px;
    font-size: 36px;
    text-align: center;
    font-family: "Tahoma", sans-serif;
    color: white;
}

/* Styles pour les bandeaux obliques */
.serviceBubble {
    width: 100%;
    padding: 40px 50px 40px 50px;
    margin: 10px 0;
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: skewY(3deg);
}

.serviceBubble1,
.serviceBubble3 {
    background-color: rgba(223, 97, 59, 0.8);
}

.serviceBubble2 {
    background-color: rgba(126, 42, 27, 0.8);
    transform: skewY(-3deg);
    z-index:4
}

.bubbleContent {
    display: flex;
    align-items: center;
    flex-direction: row;
    width:100%;
    justify-content:center
}

.serviceBubble img {
    height: 30vh;
    width: 30vh;
    object-fit: cover;
    margin: 0 20px;
}

.bubbleText {
    max-width: 40%;
}

/* Texte à droite pour les bandeaux 1 et 3 */
.serviceBubble1 .bubbleText,
.serviceBubble3 .bubbleText {
    text-align: left;
    padding-left: 20px;
}

/* Texte à gauche pour le bandeau 2 */
.serviceBubble2 .bubbleText {
    text-align: right;
    padding-right: 20px;
}

.discoverAllServicesButton {
    color: white;
    background-color: #df613b;
    border: solid 2px #7e2a1b;
    border-radius:5px;
    padding: 10px;
    font-size: 3vh;
    font-family: "Tahoma", sans-serif;
    transition : background-color ease 0.3s;
    cursor:pointer;
}

.discoverAllServicesButton a {
    text-decoration:none;
    color:white;
    font-size: 3vh;
    font-family: "Tahoma", sans-serif;
}

.discoverAllServicesButton:hover {
    background-color: #7e2a1b;
}

.contact-form-section {
    background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1)),
    url("../../medias/pictures/illustrationPictures/Pintxos.jpg");
    background-size: cover; /* Assure que l'image couvre toute la section */
    background-position: center; /* Centre l'image de fond */
    background-repeat: no-repeat; /* Empêche la répétition de l'image */
    padding-top: 3vh;
    padding-bottom: 3vh;
}