/* Styles responsive pour le header sur les appareils mobiles et tablettes */

@media (max-width: 768px) {
    .topSection {
        display: block; /* Empiler les éléments verticalement */
        text-align: center; /* Centrer le texte et le logo */
        padding: 10px 0; /* Réduire l'espacement */
    }

    .siteLogo {
        width: 20%; /* Agrandir légèrement le logo pour mobile */
        margin-bottom: 10px; /* Espacer le logo du titre */
    }

    .siteTitle {
        width: 100%; /* Le titre occupe 100% de la largeur */
        font-size: 4vh; /* Réduire la taille du titre */
        white-space: normal; /* Permet au texte de passer à la ligne si nécessaire */
    }

    /* Optionnel : Ajuster l'espacement du menu */
    nav ul {
        padding-left: 0;
        justify-content: center; /* Centrer les éléments du menu pour mobile */
        margin-right: 0; /* Supprimer la marge excessive à droite */
    }
}

.mobileNav {
    display: none; /* Masqué par défaut */
    flex-direction: column; /* Disposer les éléments verticalement (bouton + menu) */
    align-items: center; /* Centrer le bouton et les items */
}

.mobileMenuContainer {
    width:100%;
}

.burgerMenu {
    font-size: 2.5rem;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 10px; /* Ajouter de l'espace en dessous du bouton */
    width:100%;
}

.mobileMenuItems {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #df613b;
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
    padding:0;
}

.mobileMenuItems.open {
    display: flex;
    max-height: 500px;
}

.mobileMenuItems li {
    margin: 10px 0;
}

.mobileMenuItems li a {
    font-size: 1.5rem;
    color: white;
    padding: 10px;
    text-decoration: none;
    display: block;
}

.navPlaceholder {
    display:none;
}

/* Responsive rules */
@media (max-width: 768px) {
    .mobileNav {
        display: flex;
        justify-content: center; /* Centrer le menu burger */
        background-color: #df613b;

        flex-direction: column; /* Aligner le bouton burger et les items en colonne */
    }

    /* Cacher le menu desktop sur mobile */
    .desktopNav {
        display: none;
    }
}
