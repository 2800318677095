@font-face {
    font-family: 'Another Shabby';
    src: url('../../medias/fonts/AnotherShabby.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* CSS DE LA SECTION HAUTE */

.topSection {
    display: inline-flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color:black;
}

.siteLogo {
    width: 10%;
    height: auto;
}

.siteTitle {
    font-family: "Another Shabby", sans-serif;
    width: 35%;
    font-size: 8vh;
    color:white;
}

/* Sticky menu when scrolled */
.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Pour rester au-dessus des autres éléments */
    background-color: #df613b;
    transition: top 0.3s ease;
    box-shadow: 0 0 10px rgba(0,0,0,0.6);
    display: flex; /* Flexbox pour aligner stickyTopSection et les éléments du menu */
    align-items: center; /* Aligne stickyTopSection et le menu verticalement */
}

/* La section sticky ajoutée dans le menu */
.stickyTopSection {
    display: inline-flex;
    align-items: center;
    position: relative;
    left: 10%; /* Positionne la section à 10% du bord gauche */
    width: auto;
    max-height: 100%; /* Assurer que la hauteur est limitée à celle du nav */
    margin-right: auto; /* Pour pousser les éléments du menu vers la droite */
}

.stickyTopSection .siteLogo {
    width: 5%; /* Réduire la taille du logo dans le sticky */
}

.stickyTopSection .siteTitle {
    font-size: 4vh; /* Réduire la taille du texte dans le sticky */
    margin-left: 10px;
    white-space: nowrap; /* Assurer que le titre ne se divise pas en plusieurs lignes */
}

/* Autres styles pour la navigation */
nav {
    background-color: #df613b;
    width: 100%;
}

nav ul {
    display: flex;
    list-style: none;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: flex-end;
    margin-right: 10%;
    margin-bottom: 0;
    margin-top: 0;
    align-items: center; /* Alignement vertical des éléments du menu */
}

nav li {
    transition: background-color 0.5s ease;
    position: relative;
}

nav li:hover {
    background-color: #7e2a1b;
}

nav li:hover a {
    color: white;
}

nav li a {
    padding: 20px;
    display:block;
    color: white;
    text-decoration: none;
    font-family: tahoma, sans-serif;
    font-size: 2vh;
    border-right: solid 1px;
    border-left: solid 1px;
    transition: color 0.3s ease;
}

nav a:first-child {
    border-left: none;
}

nav a:last-child {
    border-right: none;
}
