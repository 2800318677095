@media (max-width: 768px) {

    .contactPageContainer{
        width:90%;
        margin-left:5%;
        background-color: rgba(0,0,0,0.7);
        margin-bottom:0;
        padding-bottom:20px;
    }

    .animated-text {
        font-size:3vh;
    }

    .contactText {
        padding : 0 5%;
    }
}