.partnersTitle{
    text-align:center;
    color:white;
    background-color:black;
    margin:0;
    padding:15px 0 15px 0;
    font-family: "Tahoma", sans-serif;
}

.partners-carousel {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    border: 0 solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:black;
    padding-top:0;
}

.carousel-wrapper {
    position: relative;
    width:100%;
    height: 200px; /* Hauteur des carrés */
    display: flex;
    overflow: hidden;
}

.carousel-hidden-left,
.carousel-hidden-right {
    position: absolute;
    top: 0;
    width: 20%; /* Cache environ 1/5 de chaque côté */
    height: 100%;
    background-color: #f9f9f9; /* Masque les couleurs */
    z-index: 2; /* Plus grand que les éléments pour les cacher */
}

.carousel-hidden-left {
    left: 0;
    background-color : black;
}

.carousel-hidden-right {
    right: 0;
    background-color : black;
}

.carousel-visible-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1; /* En dessous des conteneurs cachés */
    overflow: hidden;
}

.carousel-item {
    flex: 1; /* Occupe un cinquième de l'espace disponible */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease-in-out;
    background-color: black; /* Fond noir pour chaque carré */
    border:none;
}

.partner-logo {
    max-width: 100%;
    max-height: 70%;
    object-fit: contain; /* S'assure que l'image est contenue dans le carré */
    border:none;
}

.animate-left {
    animation: slide-left 0.5s forwards; /* Animation vers la gauche */
}

.animate-right {
    animation: slide-right 0.5s forwards; /* Animation vers la droite */
}

@keyframes slide-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slide-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.carousel-btn {
    position: absolute;
    background-color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    z-index: 10;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width:52px;
}

.prev-btn {
    left: 10%;
}

.next-btn {
    right: 10%;
}

.carousel-btn:hover {
    background-color: #ddd;
}
