/* CSS DU FOOTER */

footer {
    margin-top:0;
    border-top:white 3px solid
}

.menuFooter {
    display:flex;
    background-color: #df613b;
}

.footerItem {
    width:20%;
}

.footerItem p{
   font-family: tahoma, sans-serif;
    color:white;
}

.footerLeft {
    margin-left:3%;
    margin-right:1%;
    padding-left:1%;
    padding-right:1%;
    border-right:solid white 3px;
    color:white;
    text-align:center;
}

.footerLeft a {
    text-decoration: none;
    color:white;
    font-size:2vh;
    margin-bottom:100px;
}

.footerLeft a:hover {
    text-decoration: none;
    color: #7e2a1b;
}

.footerCenter {
    width:46%;
    padding-left: 1%;
    padding-right: 1%;
    text-align:center;
}

.footerCenter h2 {
    color:white;
    text-align:center;
    margin-bottom:10px;
}

.footerCenter p a{
    text-decoration:none;
    color:white;
}

.footerCenter p a:hover {
    color:#7e2a1b;
}

.footerCenter button {
    display: block;
    width: 20%;
    padding: 12px;
    background-color: rgb(200,200,200);
    color: #7e2a1b;
    font-size: 18px;
    border: solid 2px #7e2a1b;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-left:40%;
    margin-bottom:10px;
}

.footerCenter button:hover {
    background-color:#7e2a1b;
    border-color:black;
    color:white;
}

.footerRight {
    margin-left:1%;
    margin-right:3%;
    padding-left:1%;
    padding-right:1%;
    border-left:solid white 3px;
}

.footerRight h2 {
    color:white;
    text-align:center;
    margin-bottom:10px;
}

.socialNetworks {
    display:flex;
    justify-content:center;
    gap:20%;
    padding-bottom:10px
}

.socialButton {
    width:6vh;
    height:6vh;
}

.socialButton svg{
    width:6vh;
    height:6vh;
}

.bottomFooter{
    background-color: black;
    height: 4vh;
    margin-top:0;
    display:flex;
    justify-content:center;
    align-content:center;
    flex-direction: column;
}

.bottomFooterText{
    color:white;
    font-family:tahoma, sans-serif;
    margin:0;
    text-align: center;
}

.bottomFooterText a{
    color:white;
    text-decoration:none;
    font-weight: bold;
}

.bottomFooterText a:hover{
    color:#df613b;
    text-decoration:none;
    font-weight: bold;
}