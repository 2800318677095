/* CSS responsive pour la page d'accueil */

/* Appliquer les styles pour les appareils avec une largeur d'écran max de 768px (tablettes et mobiles) */
@media (max-width: 768px) {

    .topBannerQuote {
        width:80%;
        left:10%;
        font-size:3vh;
    }


    /* Pour les trois bandeaux */
    .servicesBubbles {
        padding: 20px; /* Réduction de l'espace pour mobile */
    }

    .serviceBubble {
        width: 100%; /* Les bandeaux prennent 100% de la largeur */
        padding: 20px; /* Réduire les marges internes */
        margin: 20px 0; /* Augmenter l'espacement vertical entre les bandeaux */
        transform: none; /* Supprimer l'effet de skew pour mobile */
    }

    .bubbleContent {
        flex-direction: column; /* Disposer l'image au-dessus du texte */
        text-align: center; /* Centrer le texte */
        align-items: center; /* Aligner le contenu au centre */
    }

    .serviceBubble img {
        width: 30vh; /* Réduire la taille de l'image sur mobile */
        height: 20vh; /* Adapter la hauteur en fonction de la largeur */
        margin: 0 auto 20px; /* Centrer l'image et ajouter un espacement en bas */
        border-radius:10px;
        box-shadow: 7px 8px 5px rgba(0,0,0,0.4);
    }

    .bubbleText {
        max-width: 90%; /* Le texte s'adapte à 90% de la largeur */
        text-align: center; /* Centrer le texte */
    }

    .discoverAllServicesButton {
        font-size: 2vh; /* Réduire la taille de la police pour mobile */
        padding: 8px; /* Ajuster le padding */
    }

    .discoverAllServicesButton a {
        font-size: 2vh; /* Réduire également la taille de la police du lien */
    }
}
