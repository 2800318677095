/* Styles responsive pour les appareils mobiles et tablettes */

@media (max-width: 768px) {
    .menuFooter {
        display: flex;
        flex-direction: column; /* Affiche les éléments en colonne */
        align-items: center; /* Centre les sections dans la colonne */
    }

    .footerItem {
        width: 100%; /* Chaque section prend 100% de la largeur */
        text-align: center; /* Centre le texte pour un meilleur rendu */
        margin: 10px 0; /* Ajoute de l'espace entre les sections */
        padding: 10px 0; /* Légère marge intérieure pour un peu d'aération */
    }

    .footerLeft, .footerCenter, .footerRight {
        margin: 0;
        padding: 0;
        border:none
    }

    .footerItem:not(:last-child) {
        border-bottom: solid 1px white; /* Ligne blanche entre les sections */
    }

    .footerLeft{
        padding-bottom:10px;
    }

    .footerCenter button {
        width:80%;
        margin-left:10%;
    }

    .bottomFooter {
        padding-top:10px;
        min-height:40px;
    }
}
