.contact-form-container {
    max-width: 60%;
    margin: 3vh auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px #df613b;
}

.contact-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.half-width {
    width: 48%; /* Réduire la largeur à 48% pour laisser de l'espace entre les colonnes */
    margin-right: 4%; /* Ajouter un espace entre les champs */
}

.half-width:last-child {
    margin-right: 0; /* Pas d'espace après le dernier élément */
}

.full-width {
    width: 100%;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.form-group label > .required {
    color: #e74c3c; /* Couleur rouge pour l'étoile */
    margin-left: 5px;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

#details {
    min-height: 40vh;
}

.form-group input.error,
.form-group textarea.error,
.form-group select.error {
    border-color: #e74c3c; /* Couleur de l'erreur */
}

.error-message {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 5px;
}

.success-message {
    color: #2ecc71;
    text-align: center;
    margin-bottom: 20px;
    font-size:20px;
    font-weight:bold;
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #df613b;
    color: white;
    font-size: 18px;
    border: solid 2px #7e2a1b;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.submit-btn:hover {
    background-color: #7e2a1b;
    color: white;
}

.note-required {
    color: #e74c3c;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}
